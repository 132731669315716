/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
@import "@ionic/angular/css/palettes/dark.system.css";
:root{
  --ion-background-color:#ffffff !important;
  --ion-color-dark:black;
  --ion-color-light:#dddddd;
}

ion-content {
    --background: #FFFFFF;
  }
  
  ion-toolbar {
    --background: white;
  }
  
  .list-md {
    background: white;
  }
  
  ion-label{
    --background: white;
  }
  
  ion-card{
    --background: white;
  }
  
  
  ion-item{
    --background: white;
    color: black;
    --color-hover: black;
  
  }
  
  ion-icon{
    color: #777;
    }
  
  ion-title{
    color: black;
  }

  sc-ion-textarea-md-h {
    --border-width: 1px;
    --border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, var(--ion-background-color-step-150, rgba(0, 0, 0, 0.13)))));
    --padding-top: 18px;
    --padding-end: 0px;
    --padding-bottom: 8px;
    --padding-start: 0px;
    --highlight-height: 2px;
    font-size: inherit;
    color: black;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  color:black;
}
ion-popover {
	// --width: calc(calc(3 / 12) * 100%);
  --offset-x: 100px;
}


.alert-wrapper.sc-ion-alert-md{
  background-color: white !important;
}

.alert-radio-label.sc-ion-alert-md{
  color:black !important;
}

.alert-title.sc-ion-alert-md{
  color:black !important;
}


.loading-wrapper.sc-ion-loading-md{
  background-color: white;
}
//to increse of size of alert
.custom-alert .alert-wrapper {
  width: 500px !important; /* Adjust width */
  max-width: 90%; /* Ensure responsiveness */
  height: auto !important;
  border-radius: 10px;
}

.custom-alert::placeholder {
  color: #808080; /* Placeholder color */
}

.custom-alert .alert-message {
  font-size: 16px;
}

.custom-alert .alert-input {
  font-size: 14px;
  color: black !important;
}

.custom-alert .alert-button {
  font-size: 16px;
}





ion-toggle {
  --background: #fff; /* Default background */
  --border-radius: 12px; /* Rounded corners */
}

/* Ensure border is visible for both checked and unchecked states */
ion-toggle::part(track) {
  border: 1px solid #9a8e8e !important;
}

ion-toggle::part(handle) {
  border: 1px solid #9a8e8e !important;
}



// ion-card {
//   transition: background-color 0.3s ease;
// }

// ion-card:hover {
//   background-color: #f0f0f0; /* Change to your preferred hover color */
// }



//css for popup
.large-modal {
  --width: 80vw;
  --height: 75vh;
  --max-width: 1000px;
  --max-height: 750px;
}

.large-modal-1 {
  --width: 80vw;
  --height: 55vh;
  --max-width: 1000px;
  --max-height: 750px;
}


.large-modal-2 {
  --width: 80vw;
  --height: 55vh;
  --max-width: 1100px;
  --max-height: 750px;
}

